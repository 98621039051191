<!-- eslint-disable vue/max-attributes-per-line -->
<!-- eslint-disable vue/singleline-html-element-content-newline -->
<template>
    <div class="verification">
        <main-steps :active="3"></main-steps>
        <div class="content">
            <div class="c-wrap">
                <title-c textc="用户身份验证"></title-c>
                <div v-if="!cType" class="code">
                    <img v-show="!pending" :src="qrcode.qrcode" alt="" class="codeimg" />
                    <p v-show="!pending">使用百度APP扫描上方二维码快速完成帐号身份验证</p>
                    <img v-show="pending" src="@/assets/img/success.png" alt="" class="success" />
                    <h1 v-show="pending">扫描成功</h1>
                    <p v-show="pending">请在手机端完成刷脸认证</p>
                </div>
                <div v-else class="public">
                    <div class="p-title">
                        <img v-show="!adopt" src="@/assets/img/point2.png" alt="1" />
                        <span v-show="!adopt">组织身份未验证</span>
                        <img v-show="adopt" src="@/assets/img/public.png" alt="" />
                        <span v-show="adopt">已验证通过</span>
                    </div>
                    <div v-show="!adopt" class="p-content">
                        当前组织主体未进行验证，请点击发起验证进行对公验证，按照
                        <span class="explain" @click="goFaq"> 《对公验证说明》 </span>
                        指引填写必要信息完成身份验证，进行对公验证期间请勿关闭本页面
                        <span id="feedback_bd"> 验证失败反馈 </span>
                    </div>
                    <div v-show="adopt" class="p-content">
                        当前用户身份已通过验证，点击下一步继续完成投诉，如修改帐号主体信息需重新进行身份验证
                    </div>
                </div>
            </div>
            <div class="btnList">
                <next-tick :btn1="0" :btn2="1" :btn3="1" :btn4="1"></next-tick>
                <el-button v-show="isType" class="type" type="primary" @click="fn">发起验证</el-button>
                <el-button v-if="adopt" type="primary" @click="next">下一步</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import MainSteps from '@/views/MainSteps.vue';
import TitleC from '@/components/publicComponents/TitleC.vue';
import NextTick from '@/components/NextTick.vue';
import { credit, result, getQrcode, getFaceResult } from '@/api/getData.js';
export default {
    components: {
        MainSteps,
        TitleC,
        'next-tick': NextTick,
    },
    data() {
        return {
            cType: true,
            qrcode: {},
            pending: false,
            timer: null,
            timer2: null,
            adopt: false,
            v_url: '',
        };
    },
    methods: {
        goFaq() {
            let routeUrl = this.$router.resolve({
                path: '/help',
                query: {
                    curid: 6,
                },
            });

            window.open(routeUrl.href, '_blank');
        },
        previous() {
            this.$router.go(-1);
        },
        next() {
            sessionStorage.setItem('active', 4);
            this.$router.push({
                path: '/confirm',
            });
        },
        fn() {
            if (!this.cType) {
                return;
            }
            if (this.v_url) {
                window.open(this.v_url, '_blank', 'fullscreen=yes,width=1230,left=800"');
            } else {
                let subject = sessionStorage.getItem('subject');
                subject = JSON.parse(subject);
                credit({
                    company: subject.cate,
                }).then((res) => {
                    this.v_url = res.data;
                    if (this.v_url) {
                        window.open(this.v_url, '_blank', 'fullscreen=yes,width=1230,left=800"');
                        this.setTimer1();
                    }
                });
            }
        },
        setTimer1() {
            // 对公轮询
            result().then((res) => {
                if (res.data === 2) {
                    // 验证成功
                    clearTimeout(this.timer2);
                    sessionStorage.setItem('isadopt', 2);
                    this.adopt = true;
                } else {
                    this.timer2 = setTimeout(() => {
                        this.setTimer1();
                    }, 5000);
                }
            });
        },
        setTimer2(aichkid) {
            // 个人轮询
            getFaceResult({
                taskId: aichkid,
            }).then((res) => {
                if (res.data === 1 || res.data === 6 || res.data === 5 || res.data === 4) {
                    //  未开始/进行中/信息验证通过
                    if (res.data === 6) {
                        this.pending = true;
                    }
                    this.timer = setTimeout(() => {
                        this.setTimer2(aichkid);
                    }, 5000);
                } else {
                    clearTimeout(this.timer);
                    if (res.data === 2) {
                        // 通过
                        sessionStorage.setItem('active', 4);
                        sessionStorage.setItem('extras', 1);
                        this.$router.push({
                            path: '/confirm',
                        });
                    } else if (res.data === 9) {
                        // 永拒，不可继续使用该认证方案
                        this.$message.error('请更换其他验证方式');
                    }
                }
            });
        },
        ginit() {
            let subject = sessionStorage.getItem('subject');
            subject = JSON.parse(subject);
            getQrcode({
                id: subject.ID,
                name: subject.cate,
            }).then((res) => {
                this.qrcode = res.data;
                this.setTimer2(res.data.aichkid);
            });
        },
    },
    computed: {
        isType() {
            return !this.adopt && this.cType;
        },
    },
    mounted() {
        let subject = sessionStorage.getItem('subject') || {};
        subject = JSON.parse(subject);
        if (subject.type === 'false') {
            this.ginit();
            this.cType = false;
        } else {
            this.cType = true;
            let isadopt = sessionStorage.getItem('isadopt') || null;
            isadopt = isadopt - 0;
            if (isadopt === 2) {
                this.adopt = true;
                return;
            }
            let extras = sessionStorage.getItem('extras') || null;
            extras = extras - 0;
            if (extras === 2) {
                this.adopt = true;
                if (subject.update) {
                    this.adopt = false;
                } else {
                    this.adopt = true;
                }
            } else {
                this.adopt = false;
            }
        }
        /**
         * @function :  插件的调用。
         * @how2use  :  使用过程，可以复制过去，仅需修改调用按钮id即可；如需透传数据信息，请联系百度反馈接口人。
         */
        (function () {
            /**
             * @function :第一次加载插件，之后调用插件
             */
            function init_feedback() {
                // eslint-disable-next-line no-undef
                if (bds && bds.qa && bds.qa.ShortCut && bds.qa.ShortCut.initRightBar) {
                    // 初始化反馈插件的样式参数
                    var fb_options = {
                        needImage: true,
                        upload_file: true,
                        appid: 258181,
                        productLine: 91212,
                        wenjuanTitle: '',
                        wenjuanURL: '',
                        issuePlaceholder: '此处只接收功能问题反馈，不接收或处理版权问题',
                        contactPlaceholder: '请输入手机号码，便于及时处理您的问题',
                        showPosition: 'bottom_right',
                        contactWayType: 'tel',
                        needContactWay: true,
                        needHotQuestion: false,
                        needQuestionnaire: false,
                        needFeedbackType: true,
                        needProductType: false,
                        needEvaluate: true,
                        typeArray: [
                            {
                                id: '95858',
                                name: 'BUG',
                            },
                            {
                                id: '95859',
                                name: '咨询',
                            },
                            {
                                id: '95860',
                                name: '建议',
                            },
                            {
                                id: '95865',
                                name: '对公打款失败',
                            },
                            {
                                id: '95866',
                                name: '实名认证失败',
                            },
                            {
                                id: '95867',
                                name: '平台系统问题',
                            },
                        ],
                        titleBgColor: '#E3EAFF',
                        buttonColor: '#6080E0',
                        mainFontColor: '#151515',
                        secondaryFontColor: '#999999',
                        titleColor: '#3B62D8',
                        hotQuestionArray: [],
                        authType: 'ufo',
                    };
                    // eslint-disable-next-line no-undef
                    bds.qa.ShortCut.initRightBar(fb_options);
                    var pro_data = {};
                    // eslint-disable-next-line no-undef
                    bds.qa.ShortCut._getProData(pro_data);
                }
            }
            /**
             * @function :校验js加载完成
             * @returns :{boolean}
             */
            function loadRightBar() {
                if (window.bds && window.bds.qa && window.bds.qa.ShortCut) {
                    init_feedback();
                } else {
                    loadScript(
                        'https://ufosdk.baidu.com/Public/feedback/js/dist/feedback_plugin_2.0.js',
                        function () {
                            init_feedback();
                        },
                        {
                            charset: 'utf-8',
                            id: 'feedback_script',
                        }
                    );
                }
                return false;
            }
            document.getElementById('feedback_bd').onclick = loadRightBar;
        })();
        // 页面加载脚本判定函数
        function loadScript(url, callback, opt) {
            var script = document.createElement('script');
            var option = option || {};
            script.type = 'text/javascript';
            if (option.charset) {
                script.charset = opt.charset;
            }
            if (option.id) {
                script.id = option.id;
            }
            if (script.readyState) {
                script.onreadystatechange = function () {
                    if (script.readyState === 'loaded' || script.readyState === 'complete') {
                        script.onreadystatechange = null;
                        callback();
                    }
                };
            } else {
                script.onload = function () {
                    callback();
                };
            }
            script.src = url;
            document.body.appendChild(script);
        }
    },
    destroyed() {
        clearTimeout(this.timer);
        clearTimeout(this.timer2);
    },
};
</script>

<style scoped lang='less'>
iframe {
    border: medium none;
}
#feedback_bd {
    color: rgb(45, 99, 224);
    &:hover {
        cursor: pointer;
    }
}
.el-button {
    background: #2d63e0;
    border-radius: 4px;
    height: 40px;
    color: #ffffff;
}

.el-button:hover {
    background: #326efa;
}

.el-button:active {
    background: #2858c7;
}

.type {
    margin-left: 10px;
}

::v-deep .form_submit {
    padding: 0 !important;
}

::v-deep .el-form {
    .el-button--default.el-button {
        background: #f5f7fc;
        color: #2d63e0;
        border: none;

        &:hover {
            color: #326efa;
        }

        &:active {
            color: #2858c7;
        }
    }
}

.content {
    position: relative;
    width: 1200px;
    min-height: 100vh;
    background: #fff;
    margin: 0 auto;
    text-align: left;

    .btnList {
        display: flex;
        justify-content: center;
        margin-top: 48px;
    }

    .public {
        width: 288px;
        margin: 40px auto;
        text-align: center;

        .p-title {
            display: inline-block;

            img {
                display: inline;
                width: 30px;
                height: 30px;
                position: relative;
                top: 5px;
            }

            span {
                color: #1e2024;
                font-size: 24px;
                font-weight: 600;
                margin-left: 5px;
            }
        }

        .p-content {
            font-size: 14px;
            color: #767981;
            margin-top: 15px;
            .explain {
                color: #2d63e0;
                text-decoration: underline;
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    .c-wrap {
        width: 700px;
        margin: 0 auto;

        .code {
            width: 190px;
            text-align: center;
            margin: 20px auto;

            .codeimg {
                width: 190px;
                height: 190px;
                border: 10px solid rgb(245, 247, 252);
                border-radius: 5px;
            }

            .success {
                width: 55px;
                height: 55px;
            }

            p {
                font-size: 14px;
                color: #767981;
            }

            h1 {
                font-size: 20px;
                margin: 10px 0 0 0;
                color: #1e2024;
                font-weight: bold;
            }

            .a {
                transform: rotate(-45deg);
                background-image: linear-gradient(211deg, #ffffff 49%, #a0baff 100%);
                box-shadow: -2px 2px 4px 0 rgba(11, 38, 160, 0.19);
            }
        }
    }
}
</style>
